/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminUpdateLoan = /* GraphQL */ `
  mutation AdminUpdateLoan(
    $actions: LoanAdminInput
    $id: ID!
    $userid: String!
  ) {
    adminUpdateLoan(actions: $actions, id: $id, userid: $userid) {
      amount_requested
      borrower_type
      comments
      currency
      date_applied
      date_approved
      date_first_payment_due
      date_issued
      date_required
      days
      disbusement_date
      fees
      id
      installment
      interests
      payment_method
      periodType
      possible_new_statuses
      principal
      profile_certified
      status
      term
      transactions {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
              __typename
            }
            loan_id
            reference
            type
            __typename
          }
          loan_id
          reference
          type
          __typename
        }
        loan_id
        reference
        type
        __typename
      }
      type
      userid
      __typename
    }
  }
`;
export const cancelUserCard = /* GraphQL */ `
  mutation CancelUserCard($userid: String) {
    cancelUserCard(userid: $userid) {
      BillingDetails {
        BillToAddress
        BillToAddress2
        BillToCity
        BillToCountry
        BillToCounty
        BillToEmail
        BillToFirstName
        BillToLastName
        BillToMobile
        BillToState
        BillToTelephone
        BillToZipPostCode
        __typename
      }
      BinCheckResults {
        BIN
        Brand
        Country
        Issuer
        Subtype
        Type
        __typename
      }
      CreditCardTransactionResults {
        AVSResult
        AuthCode
        CVV2Result
        OriginalResponseCode
        PaddedCardNumber
        ReasonCode
        ReasonCodeDescription
        ReferenceNumber
        ResponseCode
        TokenizedPAN
        __typename
      }
      FraudControlResults {
        FraudControlId
        FraudResponseCode
        ReasonCode
        ReasonCodeDesc
        ResponseCode
        Score
        __typename
      }
      IPGeoLocationResults {
        City
        CountryLong
        CountryShort
        DomainName
        IPAddress
        IPResponseCode
        IPResponseCodeDescription
        ISP
        Latitude
        Longitude
        Region
        TimeZone
        ZipCode
        __typename
      }
      MerchantId
      OrderNumber
      ShippingDetails {
        ShipToAddress
        ShipToAddress2
        ShipToCity
        ShipToCountry
        ShipToCounty
        ShipToEmail
        ShipToFirstName
        ShipToLastName
        ShipToMobile
        ShipToState
        ShipToTelephone
        ShipToZipPostCode
        __typename
      }
      Signature
      SignatureMethod
      ThreeDSResponse {
        AuthenticationResult
        CAVV
        ECIIndicator
        TransactionStain
        __typename
      }
      userid
      __typename
    }
  }
`;
export const changeCredential = /* GraphQL */ `
  mutation ChangeCredential(
    $confirmed_value: String
    $key: String
    $type: String
    $value: String
  ) {
    changeCredential(
      confirmed_value: $confirmed_value
      key: $key
      type: $type
      value: $value
    )
  }
`;
export const changeLoanSettings = /* GraphQL */ `
  mutation ChangeLoanSettings($setting: LoanSettingInput) {
    changeLoanSettings(setting: $setting) {
      max_days_oneoff
      max_days_payday
      max_days_term
      min_days_oneoff
      min_days_payday
      min_days_term
      oneoff_active
      oneoff_max_amount
      oneoff_min_amount
      payday_daily_active
      payday_daily_interest_rate
      payday_daily_max_amount
      payday_daily_min_amount
      payday_oneoff_interest_rate
      paydays
      processing_fee
      term_active
      term_daily_interest_rate
      term_max_amount
      term_min_amount
      userType
      __typename
    }
  }
`;
export const checkLoan = /* GraphQL */ `
  mutation CheckLoan($loan_id: ID!) {
    checkLoan(loan_id: $loan_id) {
      amount_requested
      borrower_type
      comments
      currency
      date_applied
      date_approved
      date_first_payment_due
      date_issued
      date_required
      days
      disbusement_date
      fees
      id
      installment
      interests
      payment_method
      periodType
      possible_new_statuses
      principal
      profile_certified
      status
      term
      transactions {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
              __typename
            }
            loan_id
            reference
            type
            __typename
          }
          loan_id
          reference
          type
          __typename
        }
        loan_id
        reference
        type
        __typename
      }
      type
      userid
      __typename
    }
  }
`;
export const createLoan = /* GraphQL */ `
  mutation CreateLoan($loan: LoanInput!, $userid: String!) {
    createLoan(loan: $loan, userid: $userid) {
      amount_requested
      borrower_type
      comments
      currency
      date_applied
      date_approved
      date_first_payment_due
      date_issued
      date_required
      days
      disbusement_date
      fees
      id
      installment
      interests
      payment_method
      periodType
      possible_new_statuses
      principal
      profile_certified
      status
      term
      transactions {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
              __typename
            }
            loan_id
            reference
            type
            __typename
          }
          loan_id
          reference
          type
          __typename
        }
        loan_id
        reference
        type
        __typename
      }
      type
      userid
      __typename
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument($key: String) {
    deleteDocument(key: $key) {
      address {
        address1
        address2
        city
        country
        __typename
      }
      bank_accounts {
        account_no
        address {
          address1
          address2
          city
          country
          __typename
        }
        bank_name
        branch_name
        deposit
        type
        __typename
      }
      birthdate
      dateRegistered
      document_keys {
        dateUploaded
        documentType
        filename
        key
        __typename
      }
      email
      employment {
        address {
          address1
          address2
          city
          country
          __typename
        }
        fromDate
        hourly_rate
        job_title
        name
        net_salary
        pay_cycle
        private_sector
        toDate
        __typename
      }
      family_name
      gender
      given_name
      home_number
      maritalStatus
      next_kin {
        name
        number
        relation
        __typename
      }
      number_of_dependents
      outstanding_loan {
        amount
        institution
        isAvailable
        principal
        reflected_on_slip
        __typename
      }
      phone_number
      social_media {
        handle
        name
        __typename
      }
      userid
      __typename
    }
  }
`;
export const disburseFunds = /* GraphQL */ `
  mutation DisburseFunds($id: ID!, $userid: String) {
    disburseFunds(id: $id, userid: $userid) {
      amount_requested
      borrower_type
      comments
      currency
      date_applied
      date_approved
      date_first_payment_due
      date_issued
      date_required
      days
      disbusement_date
      fees
      id
      installment
      interests
      payment_method
      periodType
      possible_new_statuses
      principal
      profile_certified
      status
      term
      transactions {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
              __typename
            }
            loan_id
            reference
            type
            __typename
          }
          loan_id
          reference
          type
          __typename
        }
        loan_id
        reference
        type
        __typename
      }
      type
      userid
      __typename
    }
  }
`;
export const doCardTransaction = /* GraphQL */ `
  mutation DoCardTransaction(
    $amount: Float!
    $cardCVV2: String
    $isAuth: Boolean
    $loan_id: ID!
    $userid: String!
  ) {
    doCardTransaction(
      amount: $amount
      cardCVV2: $cardCVV2
      isAuth: $isAuth
      loan_id: $loan_id
      userid: $userid
    ) {
      HTMLFormData
      ResponseCode
      ResponseCodeDescription
      TokenizedPAN
      __typename
    }
  }
`;
export const doTransaction = /* GraphQL */ `
  mutation DoTransaction(
    $entry: TransactionEntry
    $loan_id: ID!
    $userid: String!
  ) {
    doTransaction(entry: $entry, loan_id: $loan_id, userid: $userid) {
      amount_requested
      borrower_type
      comments
      currency
      date_applied
      date_approved
      date_first_payment_due
      date_issued
      date_required
      days
      disbusement_date
      fees
      id
      installment
      interests
      payment_method
      periodType
      possible_new_statuses
      principal
      profile_certified
      status
      term
      transactions {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
              __typename
            }
            loan_id
            reference
            type
            __typename
          }
          loan_id
          reference
          type
          __typename
        }
        loan_id
        reference
        type
        __typename
      }
      type
      userid
      __typename
    }
  }
`;
export const modifyPersonalInfo = /* GraphQL */ `
  mutation ModifyPersonalInfo($personalInfo: PersonalInfoInput) {
    modifyPersonalInfo(personalInfo: $personalInfo) {
      address {
        address1
        address2
        city
        country
        __typename
      }
      bank_accounts {
        account_no
        address {
          address1
          address2
          city
          country
          __typename
        }
        bank_name
        branch_name
        deposit
        type
        __typename
      }
      birthdate
      dateRegistered
      document_keys {
        dateUploaded
        documentType
        filename
        key
        __typename
      }
      email
      employment {
        address {
          address1
          address2
          city
          country
          __typename
        }
        fromDate
        hourly_rate
        job_title
        name
        net_salary
        pay_cycle
        private_sector
        toDate
        __typename
      }
      family_name
      gender
      given_name
      home_number
      maritalStatus
      next_kin {
        name
        number
        relation
        __typename
      }
      number_of_dependents
      outstanding_loan {
        amount
        institution
        isAvailable
        principal
        reflected_on_slip
        __typename
      }
      phone_number
      social_media {
        handle
        name
        __typename
      }
      userid
      __typename
    }
  }
`;
export const processLoanPayment = /* GraphQL */ `
  mutation ProcessLoanPayment(
    $amount: Float!
    $currency: String!
    $loan_id: String!
    $reference: String
    $userid: String!
  ) {
    processLoanPayment(
      amount: $amount
      currency: $currency
      loan_id: $loan_id
      reference: $reference
      userid: $userid
    )
  }
`;
export const refreshLoan = /* GraphQL */ `
  mutation RefreshLoan($loan_id: ID!, $userid: String!) {
    refreshLoan(loan_id: $loan_id, userid: $userid) {
      amount_requested
      borrower_type
      comments
      currency
      date_applied
      date_approved
      date_first_payment_due
      date_issued
      date_required
      days
      disbusement_date
      fees
      id
      installment
      interests
      payment_method
      periodType
      possible_new_statuses
      principal
      profile_certified
      status
      term
      transactions {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
              __typename
            }
            loan_id
            reference
            type
            __typename
          }
          loan_id
          reference
          type
          __typename
        }
        loan_id
        reference
        type
        __typename
      }
      type
      userid
      __typename
    }
  }
`;
export const setCardData = /* GraphQL */ `
  mutation SetCardData($id: ID!, $userid: String!) {
    setCardData(id: $id, userid: $userid) {
      BillingDetails {
        BillToAddress
        BillToAddress2
        BillToCity
        BillToCountry
        BillToCounty
        BillToEmail
        BillToFirstName
        BillToLastName
        BillToMobile
        BillToState
        BillToTelephone
        BillToZipPostCode
        __typename
      }
      BinCheckResults {
        BIN
        Brand
        Country
        Issuer
        Subtype
        Type
        __typename
      }
      CreditCardTransactionResults {
        AVSResult
        AuthCode
        CVV2Result
        OriginalResponseCode
        PaddedCardNumber
        ReasonCode
        ReasonCodeDescription
        ReferenceNumber
        ResponseCode
        TokenizedPAN
        __typename
      }
      FraudControlResults {
        FraudControlId
        FraudResponseCode
        ReasonCode
        ReasonCodeDesc
        ResponseCode
        Score
        __typename
      }
      IPGeoLocationResults {
        City
        CountryLong
        CountryShort
        DomainName
        IPAddress
        IPResponseCode
        IPResponseCodeDescription
        ISP
        Latitude
        Longitude
        Region
        TimeZone
        ZipCode
        __typename
      }
      MerchantId
      OrderNumber
      ShippingDetails {
        ShipToAddress
        ShipToAddress2
        ShipToCity
        ShipToCountry
        ShipToCounty
        ShipToEmail
        ShipToFirstName
        ShipToLastName
        ShipToMobile
        ShipToState
        ShipToTelephone
        ShipToZipPostCode
        __typename
      }
      Signature
      SignatureMethod
      ThreeDSResponse {
        AuthenticationResult
        CAVV
        ECIIndicator
        TransactionStain
        __typename
      }
      userid
      __typename
    }
  }
`;
export const updateCardTransaction = /* GraphQL */ `
  mutation UpdateCardTransaction(
    $id: ID!
    $loan_id: ID!
    $type: eCardTransactionType
    $userid: String!
  ) {
    updateCardTransaction(
      id: $id
      loan_id: $loan_id
      type: $type
      userid: $userid
    ) {
      amount_requested
      borrower_type
      comments
      currency
      date_applied
      date_approved
      date_first_payment_due
      date_issued
      date_required
      days
      disbusement_date
      fees
      id
      installment
      interests
      payment_method
      periodType
      possible_new_statuses
      principal
      profile_certified
      status
      term
      transactions {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
              __typename
            }
            loan_id
            reference
            type
            __typename
          }
          loan_id
          reference
          type
          __typename
        }
        loan_id
        reference
        type
        __typename
      }
      type
      userid
      __typename
    }
  }
`;
export const updateEmailTemplate = /* GraphQL */ `
  mutation UpdateEmailTemplate(
    $dataList: [String]
    $html: String!
    $subject: String!
    $templateName: ID
    $text: String
  ) {
    updateEmailTemplate(
      dataList: $dataList
      html: $html
      subject: $subject
      templateName: $templateName
      text: $text
    ) {
      dataList
      html
      subject
      templateName
      text
      __typename
    }
  }
`;
export const verifyCredential = /* GraphQL */ `
  mutation VerifyCredential(
    $encrypted_value: String
    $key: String
    $token: String
    $type: String
    $value: String
  ) {
    verifyCredential(
      encrypted_value: $encrypted_value
      key: $key
      token: $token
      type: $type
      value: $value
    )
  }
`;
export const withdrawLoan = /* GraphQL */ `
  mutation WithdrawLoan($id: ID!, $userid: String!) {
    withdrawLoan(id: $id, userid: $userid) {
      amount_requested
      borrower_type
      comments
      currency
      date_applied
      date_approved
      date_first_payment_due
      date_issued
      date_required
      days
      disbusement_date
      fees
      id
      installment
      interests
      payment_method
      periodType
      possible_new_statuses
      principal
      profile_certified
      status
      term
      transactions {
        amount
        cancelled
        confirmed
        created
        currency
        id
        linked_transaction {
          amount
          cancelled
          confirmed
          created
          currency
          id
          linked_transaction {
            amount
            cancelled
            confirmed
            created
            currency
            id
            linked_transaction {
              amount
              cancelled
              confirmed
              created
              currency
              id
              loan_id
              reference
              type
              __typename
            }
            loan_id
            reference
            type
            __typename
          }
          loan_id
          reference
          type
          __typename
        }
        loan_id
        reference
        type
        __typename
      }
      type
      userid
      __typename
    }
  }
`;
