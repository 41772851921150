import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";
import CryptoJS from "crypto-js";

import { API, graphqlOperation } from "aws-amplify";

import { formaTabView, checkPersonalInfo } from "@/helpers/formatPersonInfo";


const EXPIRED_PERIOD = 1000 * 60 * 60 * 24

const getStorageKey = (userid) => CryptoJS.MD5(`orenda-assets-${userid}`).toString();


export const getStoredData = (_storageKey) => {
  try {
    const current_time = Date.now();
    const ciphertext = localStorage.getItem(_storageKey);
    if (!ciphertext) return null;
    const bytes = CryptoJS.AES.decrypt(ciphertext, _storageKey);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    const { assets, last_time } = JSON.parse(decryptedText);
    if (current_time - last_time > EXPIRED_PERIOD) return null;
    console.log("assets", assets);
    return assets;
  } catch (err) {
    return null
  }
};


export const setStoredData = (assets, _storageKey) => {
  const last_time = Date.now();
  const obj = { assets, last_time };
  console.log("obj", obj);
  console.log("_storageKey", _storageKey);
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(obj), _storageKey).toString();
  console.log("ciphertext", !!ciphertext);
  localStorage.setItem(_storageKey, ciphertext);
};



export const resetStoredData = (key, value, _storageKey) => {
  const assets = getStoredData(_storageKey)
  if (assets) {
    assets[key] = value;
    setStoredData(assets, _storageKey);
  }
};

const store = { namespaced: true };

//state
store.state = {
  document: null,
  personalInfo: {},
  loaded: false,
  hostedUrl: null,
  cardData: {},
  cardDataSubscription: null,
};

// Getters ====================
store.getters = {
  tabbedPersonalInfo: (state) => {
    return formaTabView(state.personalInfo);
  },
  checkPersonalInfo: (state, getters, rootState) => {
    const { attributes } = rootState.account.user || {};

    const { email, email_verified, phone_number, phone_number_verified } =
      attributes || {};
    const EmailAddress = email && email_verified;
    const PhoneNumber = phone_number && phone_number_verified;

    return {
      ...checkPersonalInfo(state.personalInfo),
      EmailAddress,
      PhoneNumber,
    };
  },

  invalidKeys: (state, getters) => {
    return Object.keys(getters.checkPersonalInfo).reduce(
      (_invalidKeys, key) => {
        if (!getters.checkPersonalInfo[key]) _invalidKeys.push(key);
        return _invalidKeys;
      },
      []
    );
  },
  CreditCardTransactionResults: (state) => {
    return (
      (state.cardData && state.cardData.CreditCardTransactionResults) || {}
    );
  },
};

// Mutations ==================
store.mutations = {
  SET_LOADED(state, loaded) {
    state.loaded = loaded;
  },

  SET_PERSONAL_INFO(state, personalInfo) {
    state.personalInfo = personalInfo;
  },

  SET_DOCUMENT(state, document) {
    state.document = document;
  },

  SET_HOSTED_URL(state, url) {
    state.hostedUrl = url;
  },

  SET_MONITOR_CARD_SUBSCRIPTION(state, subscription) {
    state.cardDataSubscription = subscription;
  },

  SET_CARD_DATA(state, cardData) {
    state.cardData = cardData;
  },

  STOP_CARD_SUBSCRIPTION(state) {
    if (state.cardDataSubscription) state.cardDataSubscription.unsubscribe();
    state.hostedUrl = null;
  },
};

// Actions ====================
store.actions = {
  async getPersonalInfo({ commit, rootGetters }) {
    const {
      data: { loadPersonalInfo },
    } = await API.graphql(graphqlOperation(queries.loadPersonalInfo));
    const storageKey = getStorageKey(rootGetters['account/userid']);
    resetStoredData("personalInfo", loadPersonalInfo, storageKey);
    commit("SET_PERSONAL_INFO", loadPersonalInfo);
  },

  async getDocument({ commit }, key) {
    const {
      data: { getDocument },
    } = await API.graphql(graphqlOperation(queries.getDocument, { key }));
    commit("SET_DOCUMENT", getDocument);
  },

  async deleteDocument({ commit, rootGetters }, key) {
    const {
      data: { deleteDocument },
    } = await API.graphql(graphqlOperation(mutations.deleteDocument, { key }));
    const storageKey = getStorageKey(rootGetters['account/userid']);
    resetStoredData("personalInfo", deleteDocument, storageKey);
    commit("SET_PERSONAL_INFO", deleteDocument);
  },

  async loadAssets({ commit, dispatch, rootGetters }) {
    const isAdmin = !!rootGetters['account/isAdmin'];
    const userid = rootGetters['account/userid'];
    const parseAssets = (_assets) => {
      const {
        loans,
        personalInfo,
        cardData,
        allLoans,
        allPersonalInfos,
      } = _assets;
      commit("SET_CARD_DATA", cardData);
      commit("loan/SET_LOANS", loans, { root: true });
      commit("SET_PERSONAL_INFO", personalInfo);
      commit("SET_LOADED", true);
      ///////////////////admin
      if (isAdmin) {
        commit("admin_loan/SET_APPLICANTS", allPersonalInfos, { root: true });
        commit("admin_loan/SET_LOANS", allLoans, { root: true });
        commit("admin_loan/SET_LOADING", false, { root: true });
        dispatch("admin_loan/monitorAllLoans", {}, { root: true });
        console.log("allLoans", allLoans);
        console.log("allPersonalInfos", allPersonalInfos);
      }
    };

    let assets = {};
    (assets = getStoredData(getStorageKey(userid)));
    if (!assets) {
      const {
        data: { loadAssets },
      } = await API.graphql(graphqlOperation(queries.loadAssets));
      const storageKey = getStorageKey(userid);
      assets = loadAssets;
      setStoredData(assets, storageKey);
    }
    parseAssets(assets);
  },

  async modifyPersonalInfo({ commit, rootGetters }, personalInfo) {
    const {
      data: { modifyPersonalInfo },
    } = await API.graphql(
      graphqlOperation(mutations.modifyPersonalInfo, { personalInfo })
    );
    const storageKey = getStorageKey(rootGetters['account/userid']);
    resetStoredData("personalInfo", modifyPersonalInfo, storageKey);
    commit("SET_PERSONAL_INFO", modifyPersonalInfo);
  },

  async cancelUserCard({ commit, rootGetters }, userid) {
    const {
      data: { cancelUserCard: cardData },
    } = await API.graphql(
      graphqlOperation(mutations.cancelUserCard, { userid })
    );

    // SET_CARD_DATA(state, cardData)
    const storageKey = getStorageKey(rootGetters['account/userid']);
    resetStoredData("cardData", cardData, storageKey);
    commit("SET_CARD_DATA", cardData);
  },

  async getHostedPaymentUrl({ commit }, id) {
    const {
      data: { getHostedPaymentUrl: hostedUrl },
    } = await API.graphql(
      graphqlOperation(queries.getHostedPaymentUrl, { id })
    );
    console.log("hostedUrl:---", hostedUrl);
    commit("SET_HOSTED_URL", hostedUrl);
  },

  monitorCardPreAuthConfirmation({ commit, rootGetters }) {
    const userid = rootGetters["account/userid"];
    const storageKey = getStorageKey(userid);
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onCardData, {
        userid,
      })
    ).subscribe({
      next: (trxs) => {
        const cardData = trxs.value.data.onCardData;
        resetStoredData("cardData", cardData, storageKey);
        commit("SET_CARD_DATA", cardData);
      },
    });
    console.log("monitorCardPreAuthConfirmation---subscription:", subscription);
    commit("SET_MONITOR_CARD_SUBSCRIPTION", subscription);
  },
};

export default store;
