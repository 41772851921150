// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "../store/index.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/home/Index.vue"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/Index.vue"),
        },
        {
          path: "about",
          name: "About",
          component: () => import("@/views/About.vue"),
          meta: { src: require("@/assets/about.jpg") },
        },
        {
          path: "contact-us",
          name: "Contact",
          component: () => import("@/views/ContactUs.vue"),
          meta: { src: require("@/assets/contact.jpg") },
        },
        {
          path: "faq",
          name: "FAQ",
          component: () => import("@/views/Faq.vue"),
          meta: { src: require("@/assets/pro.jpg") },
        },
        {
          path: "legal",
          name: "Legal",
          component: () => import("@/views/Legal.vue"),
          meta: { src: require("@/assets/pro.jpg") },
        },

        {
          name: "Account",
          path: "account/:target",
          component: () => import("@/views/Account.vue"),
          meta: { src: require("@/assets/pricing.jpg") },
          props: true,
        },
        //////////////////////////////
        /*   {
            path: "*",
            name: "FourOhFour",
            component: () => import("@/views/404.vue"),
          }, */
        {
          path: "member/",
          component: () => import("@/layouts/home/MemberLayout.vue"),
          children: [
            /////////////////////////////////////////
            {
              name: "Dashboard",
              path: "",
              component: () => import("@/views/member/Index.vue"),
              meta: { requiresAuth: true, src: require("@/assets/pricing.jpg") },
            },
            {
              name: "Loans",
              path: "loans",
              component: () => import("@/views/member/Loans.vue"),
              meta: { requiresAuth: true, src: require("@/assets/pricing.jpg") },
            },
            {
              name: "LoanApplication",
              path: "loan-application",
              component: () => import("@/views/member/LoanApplication.vue"),
              meta: { requiresAuth: true, src: require("@/assets/pricing.jpg") },
            },
            {
              name: "Profile",
              path: "profile",
              component: () => import("@/views/member/Profile.vue"),
              meta: { requiresAuth: true, src: require("@/assets/pricing.jpg") },
            },

            {
              name: "Card",
              path: "card",
              component: () => import("@/views/member/Card.vue"),
              meta: { requiresAuth: true, src: require("@/assets/pricing.jpg") },
            },
            ////////////////////////////
            {
              path: "admin/",
              component: () => import("@/layouts/home/AdminLayout.vue"),
              children: [
                {
                  name: "Admin",
                  path: "",
                  component: () => import("@/views/member/admin/Index.vue"),
                  meta: { requiresAuth: true, src: require("@/assets/pricing.jpg") },
                },

              ]
            },
          ],
        },
      ],
    },
  ],
});
router.onError((err) => {
  console.log("router err", err);
});
router.beforeEach((to, from, next) => {
  console.log("to", to);
  console.log("from", from);
  const confirmIn = "/account/confirm-sign-in";
  const signIn = "/account/sign-in";
  console.log("to.path", to.path);
  console.log("confirmIn", confirmIn);
  console.log("signIn", signIn);
  const isMemberPage = to.path.indexOf("/member/") == 0 || to.path == "/member";  //.matched.some((x) => x.meta.requiresAuth);
  const isAdminPage = to.path.indexOf("/member/admin/") == 0 || to.path == "/member/admin";
  console.log("isMemberPage", isMemberPage);
  console.log("isAdminPage", isAdminPage);
  store.dispatch("account/fetchUser").then(() => {
    const authorized = store.getters["account/authorized"];
    const isAdmin = store.getters["account/isAdmin"];
    console.log("authorized", authorized);
    console.log("isAdmin", isAdmin);

    if (!isMemberPage || (authorized && (!isAdminPage || isAdmin))) {
      next();
    } else if (!authorized) {
      next({ name: "Account", params: { target: "sign-in" } });
    } else if (authorized) {
      next({ name: "Dashboard" });
    }
  });

});

export default router;
