/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import Currency Component

import vueDebounce from "vue-debounce";
import PortalVue from 'portal-vue'

import "./plugins";
// aws amplify  imports
//import Amplify from 'aws-amplify'
import * as AmplifyModules from 'aws-amplify'
import { Amplify } from 'aws-amplify'
import AmplifyPlugin from './amplify_ui/plugins/AmplifyPlugin'
import AmplifySettings from './aws-settings.js' // aws-exports.js

///////////////////////
import orendaSettings from "./orenda-settings.js";
import "@aws-amplify/ui";

Vue.use(PortalVue)

Vue.use(vueDebounce);

Vue.use(orendaSettings);


Amplify.configure(AmplifySettings)

Vue.use(AmplifyPlugin, AmplifyModules)
///Vue.component('amplify-authenticator', components.authenticator)
/////Vue.component('amplify-greeting', components.amplify.greeting)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
