//import * as queries from "@/graphql/queries";
//import * as mutations from "@/graphql/mutations";
//import * as subscriptions from "@/graphql/subscriptions";

//import { Auth, API, graphqlOperation } from "aws-amplify";
import { Auth } from "aws-amplify";

const store = { namespaced: true };

store.state = {
  user: { username: null },
  isAdmin: false,
  loginError: "",
  signupError: "",
  confirm: false,
  confirmError: "",
};

// Getters ====================

store.getters = {
  userid: (state) => {
    return state.user && state.user.attributes && state.user.attributes.sub;
  },
  authorized: (state) => {
    return !!(state.user && state.user.username);
  },
  isAdmin: (state, getters) => {
    if (!getters.authorized || !state.user) return false;
    const groups = state.user.signInUserSession.accessToken.payload['cognito:groups'];
    return !!(groups && groups.includes('orenda'));
  },
};

// Mutations ==================

store.mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  CONFIRM_EMAIL(state, showConfirm) {
    state.confirm = !!showConfirm;
  },
};

// Actions ====================
store.actions = {
  async login({ dispatch, state }, { email, password }) {
    state.loginError = "";
    try {
      await Auth.signIn(email, password);
      await dispatch("fetchUser", true);
    } catch (err) {
      console.log(`Login Error [${err}]`);
      state.loginError = err.message || err;
    }
  },
  async signup({ commit, state }, { email, password }) {
    state.signupError = "";
    try {
      await Auth.signUp({
        username: email,
        email: email,
        password: password,
        attributes: {
          email: email,
        },
        validationData: [],
      });
      // switch email confirmation form
      commit("CONFIRM_EMAIL", true);
    } catch (err) {
      console.log(`Signup Error [${err}]`);
      if (err) {
        state.signupError = err.message || err;
      }
      commit("CONFIRM_EMAIL", false);
    }
  },
  async confirm({ commit, state }, { email, code }) {
    state.confirmError = "";
    try {
      await Auth.confirmSignUp(email, code, {
        forceAliasCreation: true,
      });
    } catch (err) {
      console.log(`Confirm Error [${err}]`);
      if (err) {
        state.confirmError = err.message || err;
      }
      return;
    }
    commit("CONFIRM_EMAIL", false);
  },
  async authState({ commit, dispatch }, state) {
    if (state === "signedIn") {
      await dispatch("fetchUser");
    } else {
      commit("SET_USER", null);
    }
  },
  async fetchUser({ commit, dispatch }, bypassCache = false) {
    try {
      console.log("fetchUser---user");
      const user = await Auth.currentAuthenticatedUser({ bypassCache });
      console.log("fetchUser---user:", user);
      commit("SET_USER", user);
      console.log("Renewing Token");
      const expires =
        user.getSignInUserSession().getIdToken().payload.exp -
        Math.floor(Date.now() / 1000);
      console.log(`Token expires in ${expires} seconds`);
      setTimeout(() => dispatch("fetchUser"), expires * 1000);
    } catch (err) {
      console.log(err);
      commit("SET_USER", { username: null });
    }
  },

  async logout({ commit }) {
    await Auth.signOut();
    commit("SET_USER", { username: null });
  },

  async changeCredential(
    { dispatch },
    { old_password, type, value, identityChanged }
  ) {
    const password = type === "password" ? value : old_password;
    const user = await Auth.currentAuthenticatedUser();
    console.log("---user", user);
    let result = await Auth.changePassword(user, old_password, password);
    console.log("result", result);
    console.log("change password results", result);
    if (result === "SUCCESS" && type != "password") {
      let resp;
      if (identityChanged) {
        const userAttributes = {};
        userAttributes[type === "email" ? "email" : "phone_number"] = value;
        resp = await Auth.updateUserAttributes(user, userAttributes);
      } else {
        resp = await Auth.verifyCurrentUserAttribute(type);
      }
      //verifyCurrentUserAttribute
      console.log("change password results", resp);
    }
    await dispatch("fetchUser", true);
  },

  async verifyCredential({ dispatch }, { type, token }) {
    if (type !== "email") type = "phone_number";
    await Auth.verifyCurrentUserAttributeSubmit(type, token);
    await dispatch("fetchUser", true);
  },
};

export default store;
